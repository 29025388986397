@media (min-width: 240px) and (max-width: 992px) {

  .address-block {
    padding-bottom: 30px;
  }
}
@media (min-width: 240px) and (max-width: 767px) {
  .assistance_extra {
    border-top: #4e2361 1px solid;
    border-left: none;
  }
  .carousel-caption {
    position: initial;
    width: 100%;
  }
  .carousel-indicators {
    bottom: -60px;
  }
  .button_history {
    padding: 10px 31px;
  }
  .assistance_block {
    text-align: center;
  }
  .pull-center {
    text-align: center !important;
  }
  .navbar-brand {
    display: block;
  }
  .navbar-header {
    background-color: #4e2361;
    padding: 5px 15px;
    margin-top: 10px;
  }
  .navbar-default .navbar-nav > li > a {
    text-align: center;
  }
  .footer_assi {
   // margin-top: 30px;
    text-align: center;
    padding: 20px;
    position: initial;
    width: 100%;
  }
  .footer_background {
    text-align: center;
  }
  .links {
    margin-top: -35px;
  }
  .funeral_notices {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .footer_assi p {
    margin-left: 0px;
  }
  .footer_assi {
   // background-position: 30px 30px;
    padding-left: 83px;
  }

  .mar_top {
    margin-top: 0px;
  }
  .img-responsive,
  .thumbnail > img,
  .thumbnail a > img,
  .carousel-inner > .item > img,
  .carousel-inner > .item > a > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  .flexslider .slides p {
    margin-bottom: 40px !important;
  }
  .navbar-brand {
    color: #fff !important;
  }
  .mar_top p {
    margin-bottom: 30px;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #fff !important;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    text-align: center;
  }
  .dropdown-menu {
    left: 30px;
  }
  .services_page_padding {
    margin-bottom: 60px;
  }
  .services_page_padding {
    padding: 0px;
  }
  .caption-wrap .container {
    padding: 0px !important;
  }
  .funeral_notices {
    margin-left: 0px;
  }
  .question {
    margin-left: 0px;
    font-size: 17px;
  }
  .align_left {
    margin-left: 0px;
  }
  .align_left2 {
    margin-left: 0px;
  }
  .align_left3 {
    margin-left: 0px;
  }
  .align_left4 {
    margin-left: 0px;
  }
  .radio-257 .first {
    margin-right: 0px;
  }
}
