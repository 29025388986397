/*
Theme Name: MAF Theme
Theme URI: http://www.mrgraphics.com.au/
Author: Mrgraphics
Author URI: http://www.mrgraphics.com.au/
Version: 1.0
*/
@charset "utf-8";
/* CSS Document */
//@i@import '../src/sass/bootstrap4/bootstrap';
@import "includes/bootstrap";
@import "includes/other_style";
@import "includes/jquery.e-calendar";
@import "includes/media_query";
@import "includes/slick";
@import "includes/slick-theme";
@import "includes/slick-lightbox";
@import "includes/mixins";

.form-group {
  padding-left: 0px;
}
textarea.form-control {
  height: 90px;
}
.wpcf7-list-item-label {
  margin-right: 25px;
}
.services_page_padding {
  padding: 0px 90px;
}
.pp_social {
  display: none !important;
}
.dynamic_css {
  border: none !important;
}
.post-type-archive-testimonials h1 {
  color: #21486f;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 35px;
}
.post-type-archive-testimonials .testimonial-wrap {
  clear: both;
  min-height: 120px;
  background: #f7f7f7;
  margin-bottom: 25px;
  padding: 25px 35px 30px 70px;
  margin-left: 60px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
}
.post-type-archive-testimonials .client-photo {
  float: left;
  width: 100px;
  height: 100px;
  border: 6px solid #fff;
  border-radius: 50%;
  box-shadow: 1px 1px 5px #a8a7a7;
  position: absolute;
  left: 23px;
}
.post-type-archive-testimonials h2.post-title,
.post-type-archive-testimonials .client_name {
  color: #579241;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
  margin-top: 0;
}
.post-type-archive-testimonials .company {
  font-family: verdana;
  font-size: 11px;
  color: #000;
}
.post-type-archive-testimonials .post-excerpt {
  margin-bottom: 0;
}
.post-type-archive-testimonials .testimonial-wrap .post-excerpt p {
  color: #838383;
}
.testimonial-wrap {
  min-height: 280px;
  margin: 30px 0px;
  padding: 0px 35px 0px 0px;
}
div.wpcf7 {
  padding: 0px 17px !important;
}
#backurl {
  visibility: hidden;
}
.page-id-27 #backurl {
  visibility: visible;
  color: #fff;
}
.prepaid-funeral {
  padding-right: 5%;
}
@media (min-width: 260px) and (max-width: 790px) {
  .prepaid-funeral {
    padding-right: 0%;
  }
}
.fatherabove {
  padding-top: 30px;
}
/* comment box */
.comment-form-url {
  display: none;
}
#submit {
  font-size: 15px;
  line-height: 2.8;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 1.8em;
  margin: 5px 0;
  border-radius: 0.33em;
  overflow: hidden;
  z-index: 0;
  transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s, color 0.3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #4f2361;
  color: #fff;
}
#author {
  padding: 8px 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 0 2px transparent, 0 1px 0 rgba(0, 0, 0, 0.08) inset;
  transition: all 0.3s;
  background-color: #f5f5f5;
  border: 0 !important;
}
#email {
  padding: 8px 20px;
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 0 2px transparent, 0 1px 0 rgba(0, 0, 0, 0.08) inset;
  border: 0 !important;
  transition: all 0.3s;
}
#comment {
  padding: 0 12px;
  background-color: #f5f5f5;
  border: 0 !important;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 0 2px transparent, 0 1px 0 rgba(0, 0, 0, 0.08) inset;
  transition: all 0.3s;
}

.says {
  display: none !important;
}
.avatar {
  border-radius: 50% !important;
}
.fn {
  font-weight: bold !important;
}
#comments {
  display: none !important;
}
.commentlist {
  padding-left: 0;
  list-style: none;
  padding-bottom: 30px;
  margin-top: 30px;
  border-bottom: 1px solid #f5f5f5;
  border-top: 1px solid #f5f5f5;
}
.navigation {
  clear: both;
}
.reply {
  display: none;
}
.commentlist li {
  padding-top: 30px !important;
}

#respond {
  clear: both;
  padding-top: 30px;
}

.fade.show {
  opacity: 1;
  &.modal-backdrop {
      opacity: 0.6;
  }
}

.modal-dialog {
  @media (min-width: 768px) {
    width: 600px;
    margin: 80px auto;
  }
}



.d-flex {
  display: flex;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

$purple: #522d60;

.single-event {
  .basics {
    padding-top: 60px;
    .content {
      border: 1px solid black;
      padding: 60px;
      h1,
      h3 {
        margin-top: 0;
        text-align: center;
      }
    }
  }

  .slick-lightbox {
    .slick-prev:before,
    .slick-next:before,
    .slick-lightbox-close:before {
      font-size: 40px;
    }
    .slick-lightbox-close{
      top: 20px;  
      right: 40px;  
      &:before {
        padding: 0 10px 5px   ;
        line-height: 1em;
        border: 5px solid #fff;
        border-radius: 50%;
        font-weight: 700;
      }
    }
  }
  .slick {
    .thumbnail-block {
      position: relative;
      display: block;
      padding-top: 100%;
      cursor: pointer;
      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &:after {
        content: "";
        width: 32px;
        height: 32px;
        background-image: url(images/zoom-white.svg);
        background-size: 32px 32px;
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: 1;
      }
    }
  }
  .section-header {
    background-color: $purple;
    color: white;
    padding: 10px 20px;
    margin: 30px 0;
    h3 {
      color: white;
      @include breakpoint-scaling(font-size, 32px, 0.85, 0.85);
      font-style: italic;
      font-weight: 300;
      margin: 0;
    }
    .zoom-instruction {
      position: relative;
      font-size: 20px;
      padding-right: 50px;
      padding-top: 5px;
      &:after {
        content: "";
        width: 32px;
        height: 32px;
        background-image: url(images/zoom-white.svg);
        background-size: 32px 32px;
        position: absolute;
        right: 0;
        top: 2px;
      }
    }
  }
}

.lock-aspect {
  position: relative;
  &.square {
    padding-top: 100%;
  }
  &.four-three {
    padding-top: percentage(3/4);
  }
  &.sixteen-nine {
    padding-top: percentage(9/16);
  }
  .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.button {
  padding: 12px 32px;
  background-color: #ff8300;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
  &:hover {
    color: #ffffff;
    background-color: darken(#ff8300, 10);
  }
}

.obit-banner {
  @include breakpoint-scaling(height, 570px, 0.85, 0.85, 0.85);
  background-image: url(images/header-banner-untinted.jpg);
  background-size: cover;
  background-position: 50%;
  h1 {
    padding-top: 50px;
    font-weight: 100;
    color: white;
    @include breakpoint-scaling(font-size, 77px, 0.9, 0.9, 0.9);
  }
  margin-bottom: 40px;
}
.tool-pagination {
  .pages {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    .link {
      margin-right: 15px;
      margin-bottom: 15px;
      a,
      span {
        padding: 10px 20px;
        background-color: $purple;
        color: white;
        display: flex;
        align-content: center;
        justify-content: center;
        border: 2px solid $purple;
      }
      span {
        background-color: white;
        color: $purple;
        //border: 2px solid $purple;
      }
    }
  }
}
.archive-header {
  margin-bottom: 30px;
}

.search-box {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  input[type="submit"] {
    margin-top: 10px;
    //    background-color: $orange;
    margin-bottom: 0;
    @extend .read_more;
  }
}
.archives {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  ul {
    .year {
      font-weight: 700;
    }
    list-style-type: none;
    padding-left: 0;
    ul {
      padding-left: 20px;
      li.child {
        padding: 5px 0;
      }
      padding-bottom: 20px;
    }
  }
}

.sidebar-link {
  background-color: #c39ec9;
  display: block;
  $scale: 1;
  color: #fff;
  border: none;
  padding: 18px * $scale 20px * $scale;
  background-image: url(./images/icon-flowers-white.png);
  background-repeat: no-repeat;
  padding-left: 80px * $scale;
  border-radius: 30px * $scale;
  background-position: 19px * $scale 15px * $scale;
  font-size: 20px * $scale;
}

#funeral-notices {
  .no-funerals {
    min-height: 400px;
    @include breakpoint-scaling(font-size, 18px, 0.9, 0.9, 0.9);
  }
}
.has-wave {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(images/wave-thin.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 120%;
  }
}
.events-list-small {
  padding-top: 40px;
  padding-bottom: 40px;
}
a.event-link {
  color: white;
  display: block;
  &.small {
    //    transform: scale(0.7);
  }

  &.mini {
    .has-wave {
      &:before {
        background-size: 400%;
      }
    }
    h1 {
      text-align: center;
    }
    .event-banner {
      min-height: 290px;
    }
    .service-row {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
    }
    .service-date {
      width: 100%;
      text-align: center;
    }
  }
  &:hover {
    color: white;
  }
}

.event-banner {
  position: relative;

  &.extended {
    min-height: 370px;
    background-color: $purple;
    color: white;
    
    .pre-header,
    h5 {
      margin-bottom: 20px;
      
    }
  }

  &.shorter {
    background-image: url(images/single-background-untinted.jpg);
    background-size: cover;
    background-position: 50%;
    padding-top: 10px;
    padding-bottom: 20px;
    min-height: 230px;

    @include media-breakpoint-down(sm) {
      &:before {
        background-position: 90% bottom;
        background-size: 260%;
      }
      .service-row {
        text-align: center;
      }
    }

    .pre-header,
    h5 {
      font-weight: 300;
      font-style: italic;
      @include breakpoint-scaling(font-size, 28px, 0.85, 0.9);
      line-height: 1.1em;
    }
    margin-bottom: 10px;
    .container {
      position: relative;
    }
    .service-row {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
    }
  }

  #post-title {
    width: 100%;
    max-height: 80px;
  }
  .person-row {
    min-height: 140px;
    padding-top: 15px;
  }
  .person-image {
    .inner {
      border-radius: 50%;
      background-size: cover;
      background-position: 50%;
      border: 2px solid white;
      &.placeholder {
        display: none;
        background-image: url(images/ribbon_avatar.jpg);
      }
    }
  }

  .service-block {
    position: relative;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .service-date {
      padding-top: 6px;
      @include breakpoint-scaling(font-size, 20px, 0.9, 0.9, 1);
    }
    .go-button {
      background-color: lighten($purple, $amount: 10);
      padding: 4px 12px;
      border-radius: 17px;
      border: 2px solid white;
    }
  }

  .text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    position: relative;
   // top: 10px;
  }

  padding: 60px 0;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: inherit;
    line-height: 1.1em;
  }

  h1 {
    // font-size: 80px;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .pre-header,
  h5 {
    font-weight: 300;
    font-style: italic;
    font-size: 48px;
    line-height: 1.1em;
    //margin-bottom: 20px;
    @include breakpoint-scaling(font-size, 48px, 0.9, 0.9, 0.9);
  }
}

.details {
  .text {
    padding: 0 40px;
  }
  .location {
    color: $purple;
    @include breakpoint-scaling(font-size, 32px, 0.85, 0.85);
    font-weight: 600;
    line-height: 1.2em;
  }
  
  h4.service-notice {
    color: $purple;
  }

  .date-time {
    font-style: italic;
    color: $purple;
    line-height: 1.2em;
    padding-top: 20px;
    @include breakpoint-scaling(font-size, 28px, 0.85, 0.9);
  }
  a.button {
    @include breakpoint-scaling(font-size, 24px, 0.85, 0.9);
    line-height: 1.2em;
  }
  .em-location-map-container {
    width: 100% !important;
    height: 100% !important;
  }
}

/*  front page restyle */
.talking-points {
  padding-top: 30px;
  .row {
    display: flex;
    .inner {
      padding: 0 10px 20px;
      background-color: #efefef;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
    a {
      margin-top: auto;
    }
    button.read_more {
      margin: 20px 0 0;
    }
  }
}


}