
@mixin breakpoint-scaling($property, $max, $lg, $md: $lg, $sm: $md) {
	    @include media-breakpoint-up(lg) {
		  	#{$property}: $max;
	    }
	    @include media-breakpoint-between(md, lg) {
				#{$property}: round($max * $lg);
	    }
	    @include media-breakpoint-between(sm, md) {
				#{$property}: round($max * $lg * $md);
	    }
	    @include media-breakpoint-down(sm) {
				#{$property}: round($max * $lg * $md * $sm);
	    }
}

@mixin svg-background-div($truewidth, $trueheight, $actualwidth, $image) {

      $proportions: $trueheight/$truewidth;
      $height: round($actualwidth * $proportions);

      background-image: url($image);
      background-position: 50%;
      background-repeat: no-repeat;

      width: $actualwidth;
      height:$height;
      background-size: $actualwidth $height;
}

@mixin background-div($truewidth, $trueheight, $actualwidth, $image) {

      $proportions: $trueheight/$truewidth;
      $height: round($actualwidth * $proportions);

      @include background-image($image);

      width: $actualwidth;
      height:$height;
      background-size: $actualwidth $height;
}


// not so retina display Media Query
@mixin background-image($image){
    background-image: url($image);
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}