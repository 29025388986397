.calendar  * {
    box-sizing: border-box;
    font-family: 'Museo Sans 500';
    font-size: 14px;
}
.calendar-sm {
    cursor: default;
    width: 800px;
    height: 370px;
}
.calendar {
    cursor: default;
    width: 100%;
    height: auto;
}

.calendar-sm .c-pad-top {
    padding-top: 2%;
}

.calendar .c-pad-top {
    padding-top: 3%;
}

.c-grid {
    box-shadow: none;
    height: inherit;
}
.c-day {
    width: 14.28%;
    height: 13%;
    background-color: none;
    float: left;
    text-align: center;
}

.c-day-previous-month {
    width: 14.28%;
    height: 13%;
    background-color: #F9FBFD;
    float: left;
    text-align: center;
    color: gray;
}

.c-day-next-month {
    width: 14.28%;
    height: 13%;
    background-color: #F9FBFD;
    float: left;
    text-align: center;
    color: gray;
}

.c-week-day {
    width: 14.28%;
    height: 10.38%;
    background-color: none;
    color: #6d6e70;
    float: left;
    text-align: center;
    font-weight: bold;
    padding-top: 1%;
}

.c-next {
    width: 12.5%;
    height: 12%;
    padding: 2% 2% 0 2%;
    text-align: right;
    cursor: pointer;
}

.c-previous {
    width: 12.5%;
    height: 12%;
    padding: 2% 2% 0 2%;
    text-align: left;
    cursor: pointer;
}

.c-month {
    width: 75%;
    height: 12%;
    text-align: center;
}

.c-nav-btn-over {
    background-color: #acdac2 !important;
    font-weight: bold;
}

.c-today {
    background-color: #D8EAF1;
}

.c-event {
    background-color: rgb(166, 166, 166);
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.c-grid {
    float: left;
    width: 100%;
}

.c-event-grid {
    display: none;
    margin-left: 1px;
    height: inherit;
    width: 100%;
    float: left;
    box-shadow: 2px 2px 5px #888888;
    margin-top: 30px;
    
}
.c-grid-title {
    font-weight: normal;
    float: left;
    margin: 20px 0px;
    color: #6d6e70;
}

.c-event-title {
    width: 100%;
    height: 12%;
    text-align: center;
    font-weight: bold;
    background-color: #62ae85;
    color: white;
}

.c-event-body {
    background-color: #EFF4F9;
    height: 88.1%;
}

.c-event-list {
    padding: 7 0 0 0;
    overflow: auto;
    height: 95%;
}

.c-event-item > .title {
    font-weight: bold;
}

.c-event-item > div {
    text-overflow: ellipsis;
    width: inherit;
    overflow: hidden;
    white-space: nowrap;
}

.c-event-item {
    padding: 30px 10px;
    margin-bottom: 10px;
    text-align: center;
}

.c-event-over {
    background-color: lightgray;
    font-weight: bold;
    color: black;
}

.c-event-over > .description {
    font-weight: normal;
}