/* @font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal; */

//}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 0.02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

@mixin breakpoint-vals($property, $max, $xl, $lg, $md, $sm: $md) {
  @include media-breakpoint-up(xl) {
    #{$property}: $max;
  }
  @include media-breakpoint-between(lg, xl) {
    #{$property}: $xl;
  }
  @include media-breakpoint-between(md, lg) {
    #{$property}: $lg;
  }
  @include media-breakpoint-between(sm, md) {
    #{$property}: $md;
  }
  @include media-breakpoint-down(sm) {
    #{$property}: $sm;
  }
}
body {
  overflow-x: hidden;
  line-height: 1.8em;
}
.pb10 {
  padding-bottom: 10px;
}
.assistance_block {
  color: #4e2361;
  padding: 0px 10px;
  text-align: right;
  line-height: 1.5;
  font-size: 18px;
}
.pull-right {
  @include media-breakpoint-down(sm) {
    float: none !important;
  }
}

.header-assistance {
  display: inline-block;
  vertical-align: top;
  @include media-breakpoint-down(sm) {
    display: block;
    margin-top: 40px;
  }
}
.assistance_extra {
  display: inline-block;
  border-left: #4e2361 1px solid;
  padding: 0px 10px;
  line-height: 2;
}
.assistance_extra p a:hover {
  color: #4e2361 !important;
}

.assistance_extra h4 {
  margin-bottom: 0px;
}
.button_history {
  padding: 10px 20px;
  border: none;
  background-color: #4e2361;
  color: #fff;
  text-decoration: none;
}
.button_history:hover {
  background-color: #ff8300;
  color: #fff;
}
.about_button {
  padding: 8px 20px;
  border: none;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  text-decoration: none;
}
.about_button:hover {
  background-color: #fff;
  color: #4e2361;
}
.navbar-brand {
  display: none;
}
.footer_background {
  background-color: #e6e7e8;
  margin-top: 30px;
  padding: 40px 0px;
  color: #594a41;
}
.footer_background h5 {
  color: #4e2361;
  font-size: 18px;
}
.footer_background ul li {
  list-style-type: none;
}
.carousel {
  margin-bottom: 70px;
}
.links {
  margin-top: 35px;
}
.footer_background ul {
  padding-left: 0px;
}
.footer_background a {
  color: #594a41;
}
.footer_background a:hover {
  color: #f5821f;
}

.button-holder {
  padding-top: 80px;
}

.footer_assi {
  background-color: #f5821f;
  padding: 20px 0 20px 75px;
  color: #e6e7e8;
  border: none;
  position: absolute;
  top: 0;
  right: -15px;
  width: 100%;
  border-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-image: url(./images/icon-phone.png);
  background-repeat: no-repeat;
  background-position: 30px 15px;
  font-size: 16px;
  &:after {
    background-color: #f5821f;
    content: "";
    position: absolute;
    left: 99%;
    top: 0;
    bottom: 0;
    width: 4000px;
    @include media-breakpoint-down(sm) {
      content: none;
    }
  }
}
.footer_assi p {
  margin-left: -5%;
}
.copyright {
  color: #594a41;
  padding: 15px;
}
.copyright a {
  color: #594a41;
}
.copyright a:hover {
  color: #f5821f;
}
.middle_wrapper {
  background-image: url(./images/maf-bg-pattern.jpg);
  background-size: cover;
  color: #594a41;
  padding: 60px 0px;
  margin-top: -40px;
}
.middle_wrapper h5 {
  color: #4e2361;
  font-size: 17px;
}
.flower {
  background-image: url(./images/icon-flowers.png);
  background-repeat: no-repeat;
  height: 65px;
  padding-left: 80px;
  padding-top: 25px;
}
.van {
  background-image: url(./images/icon-vehicle.png);
  background-repeat: no-repeat;
  height: 65px;
  padding-left: 80px;
  padding-top: 25px;
}
.house {
  background-image: url(./images/icon-church.png);
  background-repeat: no-repeat;
  height: 65px;
  padding-left: 80px;
  padding-top: 25px;
}
.box {
  background-image: url(./images/icon-casket.png);
  background-repeat: no-repeat;
  height: 65px;
  padding-left: 80px;
  padding-top: 25px;
}
.read_more {
  padding: 8px 15px;
  background-color: #ff8300;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.read_more:hover {
  background-color: #4e2361;
}
.question_block {
  background-color: #4e2361;
  padding: 25px 0px;
  color: #fff;
}
.funeral_notices {
  background-color: #c39ec9;
  color: #fff;
  border: none;
  padding: 18px 20px;
  background-image: url(./images/icon-flowers-white.png);
  background-repeat: no-repeat;
  padding-left: 70px;
  border-radius: 30px;
  background-position: 19px 15px;
  margin-left: 35px;
  font-size: 20px;
}
.funeral_notices:hover {
  background-color: #ff8300;
  color: #fff;
}
.question {
  background-color: transparent;
  padding: 15px 25px;
  background-image: url(./images/icon-help.png);
  border: 1px solid #fff;
  background-repeat: no-repeat;
  padding-left: 60px;
  border-radius: 30px;
  background-position: 10px;
  margin-left: 35px;
  font-size: 20px;
  color: #fff;
}
.question:hover {
  background-color: #c39ec9;
  color: #4e2361;
}
.violet_wrapper {
  font-size: 25px;
  @include media-breakpoint-down(lg) {
    margin-left: 30px;
    margin-bottom: 20px;
    display: block !important;
  }
}

.center_wrapper {
  background-color: #fff;
  padding: 60px 0px;
  color: #594a41;
}
.center_wrapper h4 {
  color: #f5821f;
  margin-bottom: 40px;
}
.read_more_orange {
  padding: 8px 15px;
  background-color: #c39ec9;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  margin: 30px 0px 10px 0px;
  float: right;
}
.read_more_orange:hover {
  background-color: #4e2361;
}
.our_services p {
  color: #594a41;
}
.our_services h4 {
  margin: 20px 0px;
}
.our1 {
  background-image: url(./images/icon-24-hour.png);
  background-repeat: no-repeat;
  padding-left: 60px;
  background-position: 10px;
  margin-bottom: 15px;
}
.our2 {
  background-image: url(./images/icon-chapel.png);
  background-repeat: no-repeat;
  padding-left: 60px;
  background-position: 10px;
  margin-bottom: 15px;
}
.our3 {
  background-image: url(./images/icon-staff.png);
  background-repeat: no-repeat;
  padding-left: 60px;
  background-position: 10px;
  margin-bottom: 15px;
}
.our4 {
  background-image: url(./images/icon-prestige-vehicle.png);
  background-repeat: no-repeat;
  padding-left: 60px;
  background-position: 10px;
  margin-bottom: 15px;
}
.our5 {
  background-image: url(./images/icon-celebrant.png);
  background-repeat: no-repeat;
  padding-left: 60px;
  background-position: 10px;
  margin-bottom: 15px;
}
.our6 {
  background-image: url(./images/icon-thankyou-card.png);
  background-repeat: no-repeat;
  padding-left: 60px;
  background-position: 10px;
  margin-bottom: 15px;
}
.our7 {
  background-image: url(./images/icon-certification.png);
  background-repeat: no-repeat;
  padding-left: 60px;
  background-position: 10px;
  margin-bottom: 15px;
}
.our8 {
  background-image: url(./images/icon-news.png);
  background-repeat: no-repeat;
  padding-left: 60px;
  background-position: 10px;
  margin-bottom: 15px;
}
.our9 {
  background-image: url(./images/icon-maf.png);
  background-repeat: no-repeat;
  padding-left: 60px;
  background-position: 10px;
  margin-bottom: 15px;
}
.carousel-caption h1 {
  color: #fff;
}
.nav-tabs li a {
  color: #4e2361;
  font-size: 20px;
}
.nav-tabs > li.active > a,
.nav-tabs > li > a.active:hover,
.nav-tabs > li > a.active:focus,
.nav-tabs > li > a.active {
  color: #f5821f;
  background-color: initial;
  border-top: 1px solid #f5821f;
}
.date_button {
  background-color: #4e2361;
  color: #fff;
  padding: 10px 15px;
  border: none;
  font-size: 20px;
  margin-top: 5px;
  min-width: 96px;
}
.event_list {
  border-bottom: 1px solid #4e2361;
  margin-bottom: 40px;
  margin-top: 20px;
}
.event_link a {
  color: #f5821f;
}
.search_by_month h5 {
  color: #594a41;
}
.dropdown-menu {
  background-color: #4e2361;
  color: #fff;
}
ul.dropdown-menu li a {
  color: #fff;
}
.dropdown-menu > li > a {
  padding: 10px 20px;
  border-bottom: 1px solid #fff;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #ff8300;
}
table {
  width: 100%;
}
.metaslider .caption-wrap {
  position: initial !important;
  opacity: initial !important;
  background: none !important;
}
.metaslider .caption {
  padding: 0px !important;
}
.services_page_padding p {
  line-height: 2em;
}
.footer_mail:hover {
  color: #4e2361 !important;
}
div.css-search input.em-search-text,
div.css-search input.em-search-geo {
  width: 75% !important;
  font-size: 16px;
  line-height: 16px;
  padding: 18px 10px !important;
  outline: none !important;
  color: #666;
  border: 1px solid #c39ec9 !important;
  margin-bottom: 25px;
}
div.css-search {
  border: none !important;
}
div.css-search div.em-search-text {
  background: none !important;
  padding-left: 0px !important;
}
.em-calendar thead {
  display: none;
}
.days-names {
  margin-bottom: 20px;
}
.search_by_month a {
  color: #594a41;
}
.pastevents-archeive-funeral {
  cursor: pointer;
  padding: 5px 0px;
}
/* 
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #c39ec9 url("./images/ajax-loader.gif") 50% 50% no-repeat;
  opacity: 0.6;
} */

#addFrom.show.fade .modal-dialog {
  .modal-body {
    padding-bottom: 30px;
  }
  h4.top-margin {
    margin-top: 20px;
  }
  transform: translate(0, 0);
  input[type="text"],
  input[type="email"],
  textarea {
    width: 90%;
  }
  input[type="submit"] {
    margin-top: 20px;
  }
  label {
    width: 100%;
  }
  label {
    margin-top: 20px;
  }
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading {
  overflow: hidden;
}

/* Anytime the body has the loading class, our
   modal element will be visible */
body.loading .modal {
  display: block;
}
.inner-header-bg {
  background-image: url("./images/maf-bg-header-inner.jpg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100px;
  background-size: cover;
}
.mar_top {
  margin-top: 28px;
}

.flex-control-paging li a.flex-active {
  width: 10px !important;
  height: 10px !important;
  margin: 0 !important;
  border: 1px solid #4e2361 !important;
  margin-right: 10px !important;
  box-shadow: none !important;
}

.flex-control-paging li a {
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  text-indent: -9999px !important;
  cursor: pointer !important;
  background-color: transparent !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #e6e7e8 !important;
  border-radius: 0px !important;
  margin-right: 10px !important;
  box-shadow: none !important;
}
.hide-heading h2 {
  display: none;
}
.css-events-list h2 {
  color: #4e2361;
  font-size: 22px;
}
.separator-doubled {
  display: none;
}
div.wpcf7-mail-sent-ok {
  position: absolute;
  bottom: 0;
}
div.wpcf7-validation-errors {
  position: absolute;
  bottom: 0;
}
.align_left {
  margin-left: -88px;
}
.align_left2 {
  margin-left: -92px;
}
.align_left3 {
  margin-left: -56px;
}
.align_left4 {
  margin-left: -32px;
}
.radio-257 .first {
  margin-right: 72px;
}
.bx-wrapper img {
  border-radius: 0px !important;
}
.nav > li > a {
  padding: 10px 12px !important;
}
div#n2-ss-3 .n2-style-8c39bd1b5d1c821102353bb13550e669-simple,
div#n2-ss-4 .n2-style-8c39bd1b5d1c821102353bb13550e669-simple {
  background: #fff !important;
  padding-top: 15px !important;
}
div#n2-ss-4 .nextend-thumbnail-horizontal .nextend-thumbnail-button,
div#n2-ss-3 .nextend-thumbnail-horizontal .nextend-thumbnail-button {
  margin-top: 0px !important;
}
#gallery-5 .gallery-item,
#gallery-6 .gallery-item,
#gallery-1 .gallery-item,
#gallery-7 .gallery-item {
  text-align: left !important;
}

.gallery-columns-5,
.gallery-columns-4 {
  margin: auto;
  .gallery-item {
    float: left;
    margin-top: 10px;
    text-align: center;
    width: 20%;
    @include breakpoint-vals(width, 20%, 20%, 25%, 33%, 50%);

    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    img {
      border: 2px solid #cfcfcf;
    }
    .gallery-caption {
      margin-left: 0;
    }
  }
}

.staff-member {
  .staff-member-title {
    color: #4e2361;
    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }
}
/* 
// For displaying 3 columns on tablet 
@media only screen and (max-width: 800px) {
  .gallery-columns-5 .gallery-item {
    width: 33%;
  }
}

// For displaying single column on mobile 
@media only screen and (max-width: 480px) {
  .gallery-columns-5 .gallery-item {
    width: 100%;
  }
}

.gallery-columns-5 .gallery-item:nth-child(5n + 1) {
  clear: none;
}
 */
